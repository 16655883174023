import type { SubscriptionState } from '$lib/client/subscription_utils';
import { writable } from 'svelte/store';

// const empty_state: SubscriptionState = {products: [], subscriptions: []};
// export const subscription_products = writable(empty_state as SubscriptionState);

export type SubscriptionStoreEventType = 'uninitialized'
                                         | 'complete_initialization' 
                                         | 'product_updated' 
                                         | 'receipts_ready' 
                                         | 'receipts_updated' 
                                         | 'approved_transaction' 
                                         | 'verified' 
                                         | 'unverified' 
                                         | 'purchase'
                                         | 'complete_purchase' 
                                         | 'refresh' 
                                         | 'complete_refresh' 
                                         | 'error' 
                                         | 'pending'
                                         | 'reset_state';

class SubscriptionStoreState {
    
    private is_initialized: boolean = false;
    private is_refreshing: boolean = false;
    private is_verifying: boolean = false;
    private is_payment_cancelled_by_user: boolean = false;
    private purchasing_product_id?: string;
    private pending_transactions?: CdvPurchase.Transaction[]; 

    private _state: SubscriptionState;
    private _error?: CdvPurchase.IError;
    private _event_type: SubscriptionStoreEventType = 'uninitialized';

    constructor() {
        this._state = {products: [], subscriptions: []};
    }

    public get event_type(): SubscriptionStoreEventType {
        return this._event_type;
    }

    // state initialization
    complete_initialization() {
        this.is_initialized = true;
        this._event_type = 'complete_initialization';
    }

    public get initialized(): boolean {
            
        return this.is_initialized;
    }

    // product update
    product_updated(state: SubscriptionState) {
        this._state = state;
        this._event_type = 'product_updated';
    }

    // processing lifecycle methods
    initiate_purchase(product_id: string) {
        this.reset_state();
        this.purchasing_product_id = product_id;
        this._event_type = 'purchase';
    }

    complete_purchase(state: SubscriptionState) {
        this._state = state;
        this.purchasing_product_id = undefined;
        this._event_type = 'complete_purchase';
    }

    public get is_purchasing_product(): boolean {

        return this.purchasing_product_id !== undefined;
    }

    // refresh store
    initiate_refresh() {
        this.is_refreshing = true;
        this._event_type = 'refresh';
    }

    complete_refresh() {
        this.is_refreshing = false;
        this._event_type = 'complete_refresh';
    }

    public get refreshing(): boolean {
            
        return this.is_refreshing;
    }

    // verify store
    // initiate_verify() {
    //     this.is_verifying = true;
    // }

    // complete_verify() {
    //     this.is_verifying = false;
    // }
    verified(state: SubscriptionState) {
        this._state = state;
        this.is_verifying = false;
        // TODO: process store.verifiedPurchases
        this._event_type = 'verified';
    }

    unverified(state: SubscriptionState) {
        this._state = state;
        this.is_verifying = false;
        this.purchasing_product_id = undefined;
        this._event_type = 'unverified';
    }

    public get verifying(): boolean {
            
        return this.is_verifying;
    }

    // receipt
    receipts_ready(state: SubscriptionState) {
        this._state = state;
        // TODO: process store.localTransactions
        this._event_type = 'receipts_ready';
    }

    receipts_updated(state: SubscriptionState) {
        this._state = state;
        // TODO: process store.localTransactions
        this._event_type = 'receipts_updated';
    }

    // represents that payment was successful
    approved_transaction(state: SubscriptionState) {
        this._state = state;
        this.is_verifying = true;
        this._event_type = 'approved_transaction';
    }

    pending_purchase(pending_transactions: CdvPurchase.Transaction[], state: SubscriptionState) {
        this._state = state;
        this.is_verifying = false;
        this.pending_transactions = pending_transactions;
        this._event_type = 'pending';
    }

    // state modification
    // register_subscription_state(subscription_state: SubscriptionState) {
    //     this.state = subscription_state;
    // }

    set_error(error: CdvPurchase.IError) {
        // alert(error.message);
        this.reset_state();
        this._error = error;
        if (error?.code === CdvPurchase.ErrorCode.PAYMENT_CANCELLED) {
            this.is_payment_cancelled_by_user = true;
        }
        this._event_type = 'error';
    }

    public get payment_cancelled_by_user(): boolean {
        return this.is_payment_cancelled_by_user;
    }

    public get error(): CdvPurchase.IError | undefined {
        return this._error;
    }

    reset_state() {

        this.is_refreshing = false;
        this.is_verifying = false;
        this.is_payment_cancelled_by_user = false;
        this.pending_transactions = undefined;

        this.purchasing_product_id = undefined;
        this._error = undefined;
        this._event_type = 'reset_state';
    }

    public get state(): SubscriptionState {
        return this._state;
    }
}

function build_subscription_store() {
    let store: SubscriptionStoreState = new SubscriptionStoreState();
    const { subscribe, update } = writable(store);

    return {
        subscribe,
        state: () => store,
        complete_initialization: () =>
            update((store: SubscriptionStoreState) => { store.complete_initialization();
                                                        return store; }),
        product_updated: (subscription_state: SubscriptionState) =>
            update((store: SubscriptionStoreState) => { store.product_updated(subscription_state);
                                                        return store; }),
        receipts_ready: (subscription_state: SubscriptionState) =>
            update((store: SubscriptionStoreState) => { store.receipts_ready(subscription_state);
                                                        return store; }),
        receipts_updated: (subscription_state: SubscriptionState) =>
            update((store: SubscriptionStoreState) => { store.receipts_updated(subscription_state);
                                                        return store; }),
        approved_transaction: (subscription_state: SubscriptionState) =>
            update((store: SubscriptionStoreState) => { store.approved_transaction(subscription_state);
                                                        return store; }),
        verified: (subscription_state: SubscriptionState) =>
            update((store: SubscriptionStoreState) => { store.verified(subscription_state);
                                                        return store; }),
        unverified: (subscription_state: SubscriptionState) =>
            update((store: SubscriptionStoreState) => { store.unverified(subscription_state);
                                                        return store; }),
        purchase: (product_id: string) => 
            update((state: SubscriptionStoreState) => { state.initiate_purchase(product_id);
                                                        return state; }),
        complete_purchase: (subscription_state: SubscriptionState) => 
            update((state: SubscriptionStoreState) => { state.complete_purchase(subscription_state);
                                                        return state; }),
        refresh: () => 
            update((state: SubscriptionStoreState) => { state.initiate_refresh();
                                                        return state; }),
        complete_refresh: () => 
            update((state: SubscriptionStoreState) => { state.complete_refresh();
                                                        return state; }),
        pending_purchase: (pending_transactions: CdvPurchase.Transaction[], subscription_state: SubscriptionState) => 
            update((state: SubscriptionStoreState) => { state.pending_purchase(pending_transactions, subscription_state);
                                                        return state; }),
        // restore_purchase: () =>
        //     update((state: SubscriptionStoreState) => { state.initiate_restore_purchase();
        //                                                 return state; }),
        // complete_restore_purchase: () =>
        //     update((state: SubscriptionStoreState) => { state.complete_restore_purchase();
        //                                                 return state; }),
        // register_subscription_state: (subscription_state: SubscriptionState) =>
        //     update((state: SubscriptionStoreState) => { state.register_subscription_state(subscription_state);
        //                                                 return state; })
        set_error: (error: CdvPurchase.IError) =>
            update((state: SubscriptionStoreState) => { state.set_error(error);
                                                        return state; }),
        reset_state: () =>
            update((state: SubscriptionStoreState) => { state.reset_state();
                                                        return state; })
    };       
};

export const subscription_store = build_subscription_store();